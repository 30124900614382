<template>
    <div>
        <canvas id='main-scene'></canvas>
    </div>
</template>
<style lang="scss" scoped>
#main-scene{
    width: 100%;
    height: 100vh;
}
</style>
<script>
import * as Babylon from "@babylonjs/core"; 
export default {
    data(){
        return{
            engine: null,
        }
    },
    methods:{
        init(){
            this.engine = new Babylon.Engine(document.getElementById('main-scene'), true);
            this.engine.runRenderLoop(()=>{
                scene.render();
                this.engine.resize();
            });
            let scene = new Babylon.Scene(this.engine);
            scene.createDefaultCamera(true, true, true);
            scene.createDefaultEnvironment();
            let filePath = this.$route.query.file;
            Babylon.SceneLoader.Append('',filePath, scene, ()=>{
                console.log('successful!');
            })
        },
    },
    mounted(){
        this.init();
    },
    destroyed(){
        this.engine.stopRenderLoop();
    }
}
</script>